<template>
  <BModal
    id="modal-upload-ticket"
    hide-footer
    hide-header
    modal-class="modal-dark"
    centered
    no-close-on-backdrop
    no-close-on-esc
    size="md"
  >
    <div class="text-center text-black my-4 mx-2">
      <strong class="font-bold text-3xl">
        Upload File
      </strong>
      <p class="mt-1 mb-2">
        Untuk dapat merespon tiket secara massal, kamu harus upload file terlebih dahulu
      </p>
      <input
        id="upload"
        type="file"
        class="d-none"
        @change="validateFile()"
      >
      <label for="upload">
        <div
          class="outline-dashed outline-[#F95031] p-2 w-[30rem]"
        >
          <div v-if="!isExcel">
            <img
              class="my-2 d-inline"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-text-1.svg"
              alt="document"
            >
            <div class="font-bold mb-1">
              Drop file disini, atau <span class="text-primary">pilih</span>
            </div>
            <small>(*file berekstensi (.xlsx) excel dan pastikan di dalam file excel (.xlsx) tidak ada input gambar maupun video)</small>
          </div>
          <div v-else>
            <div class="relative my-1">
              <img
                class="d-inline absolute right-40 -top-3 w-5"
                type="button"
                src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
                alt="close"
                @click="deleteFile()"
              >
              <img
                class="d-inline w-20"
                src="https://storage.googleapis.com/komerce/assets/icons/xls.png"
                alt="xls"
              >
            </div>
            <small>{{ fileName }}</small>
          </div>
        </div>
        <div
          v-if="isUpload"
          class="row mt-1"
        >
          <div class="col-sm-1">
            <span class="k-export-2 text-2xl text-[#34A770]" />
          </div>
          <div class="col-sm-11 align-self-center">
            <b-progress
              :value="barValue"
              max="100"
              animated
              variant="success"
            />
          </div>
        </div>
      </label>
      <div class="my-2 d-flex justify-between">
        <div>
          <b-button
            variant="outline-primary"
            class="w-56"
            @click="closeModal()"
          >
            Kembali
          </b-button>
        </div>
        <div>
          <b-button
            :variant="disableSubmit ? 'secondary' : 'primary'"
            class="w-56"
            :disabled="disableSubmit"
            @click="submit"
          >
            <b-spinner
              v-if="isUpload"
              small
            />
            Submit
          </b-button>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      disableSubmit: true,
      isExcel: false,
      fileName: '',
      fileInput: null,
      barValue: 0,
      isUpload: false,
      fileUpload: null,
    }
  },
  methods: {
    validateFile() {
      this.fileInput = document.getElementById('upload')
      const allowedExtensions = ['xlsx']
      const selectedFile = this.fileInput.files[0]

      this.fileName = selectedFile.name
      // eslint-disable-next-line no-bitwise
      const fileExtension = this.fileName.slice((this.fileName.lastIndexOf('.') - 1 >>> 0) + 2)
      this.fileUpload = selectedFile

      if (allowedExtensions.includes(fileExtension.toLowerCase())) {
        this.isExcel = true
        this.disableSubmit = false
      } else {
        this.fileInput.value = ''
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'File harus berekstensi (.xlsx)',
            variant: 'danger',
          },
        }, 2000)
      }
    },
    submit() {
      this.disableSubmit = true
      this.isUpload = true
      const interval = setInterval(() => {
        this.barValue += 1
        if (this.barValue >= 100) {
          clearInterval(interval)
        }
      })
      const url = 'komship/api/v1/ticket/mitra/upload-excel'
      const formData = new FormData()
      formData.append('file_excel', this.fileUpload)
      this.$http_new.post(url, formData)
        .then(res => {
          const { data } = res.data
          this.barValue = 100
          const isSuccess = data.status_queue === 0
          let text
          let button
          if (isSuccess) {
            text = `${data.appropriate} Sesuai dan ${data.not_appropriate} Belum sesuai`
            button = '<button class="btn btn-outline-primary btn-sm ml-1" id="downloadButton">Download</button>'
          } else {
            text = 'Tunggu 2 - 5 menit'
            button = ''
          }
          this.$bvModal.hide('modal-upload-ticket')
          this.$swal.fire({
            title: 'Hasil Respon Massal',
            width: 500,
            // eslint-disable-next-line no-useless-concat
            html: `${text}` + `${button}`,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Lihat',
            cancelButtonColor: '#FFFFFF',
            confirmButtonClass: 'btn btn-primary w-full',
          }).then(isConfirm => {
            if (isConfirm) {
              this.$emit('get-fetch')
            }
          })

          document.getElementById('downloadButton').addEventListener('click', () => {
            this.download(data.id)
          })
          this.barValue = 0
          this.disableSubmit = true
          this.isUpload = false
          this.isExcel = false
        })
        .catch(err => {
          let text
          if (err.response.data.code === 1001) {
            text = 'Cek kembali format excel kamu'
          } else {
            text = 'Gagal upload file excel'
          }
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text,
              variant: 'danger',
            },
          }, 2000)
          this.barValue = 0
          this.disableSubmit = true
          this.isUpload = false
          this.isExcel = false
        })
    },
    download(id) {
      const payload = { id }
      this.$emit('download-fail', payload)
    },
    closeModal() {
      this.isExcel = false
      this.fileInput = null
      this.disableSubmit = true
      this.$bvModal.hide('modal-upload-ticket')
    },
    deleteFile() {
      this.isExcel = false
      this.fileInput = null
      this.disableSubmit = true
    },
  },
}
</script>
